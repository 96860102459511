<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div
      class="
        bookmark-wrapper
        align-items-center
        flex-lg-grow-1
        d-flex d-lg-none
      "
    >
      <b-navbar-nav class="nav align-items-center">
        <!-- <locale /> -->
        <div class="ml-1">
          <span> {{ $t("selectedPlantName") }}</span>
          <h5>
            {{ selectedPlantName }}
          </h5>
        </div>
        <div class="ml-1">
          <span> {{ $t("selectedModel") }}</span>
          <h5>
            {{ $store.state.app.selectedModelId == 1 ? "NASEM" : "INRA" }}
          </h5>
        </div>
      </b-navbar-nav>
    </div>
    <div
      class="flex-row align-items-center dropdown-user-link d-none d-lg-flex"
    >
      <b-img
        v-if="companyPictureUrl"
        :src="
          'https://api.optiformula.com/Company_Pictures/' + companyPictureUrl
        "
        height="50px"
      ></b-img>
      <div class="ml-2">
        <span> {{ $t("selectedPlantName") }}</span>
        <h5>
          {{ selectedPlantName }}
        </h5>
      </div>
      <div class="ml-1">
        <span> {{ $t("selectedModel") }}</span>
        <h5>
          {{ $store.state.app.selectedModelId == 1 ? "NASEM" : "INRA" }}
        </h5>
      </div>
      <b-navbar-nav class="nav align-items-center"> </b-navbar-nav>
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <div class="mr-2 d-flex">
        <dark-Toggler class="d-flex" />
        <locale class="d-none d-md-flex"></locale>
        <cart-dropdown />
      </div>
      <b-link
        :to="{
          name: 'profile',
          params: { id: userData.id },
        }"
        class="
          d-sm-flex
          flex-column
          align-items-end
          d-none
          user-nav
          ml-auto
          text-secondary
        "
      >
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name }} {{ userData.surname }}
        </p>
        <span class="user-status">
          {{
            userData != null && userData.companyname
              ? userData.companyname.substring(0, 22) +
                (userData.companyname.length > 22 ? "..." : "")
              : ""
          }}
        </span>
      </b-link>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <b-avatar
            :key="pictureUrl"
            size="40"
            variant="light-primary"
            badge
            :src="'https://api.optiformula.com/User_Pictures/' + pictureUrl"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{
            name: 'profile',
            params: { id: userData.id },
          }"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t("myAccount") }}</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span> {{ $t("logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BImg,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import CartDropdown from "@/@core/layouts/components/app-navbar/components/CartDropdown.vue";
export default {
  components: {
    BImg,
    BLink,
    BAvatar,
    BNavbarNav,
    BDropdownItem,
    BDropdownDivider,
    BNavItemDropdown,
    // Navbar Components
    Locale,
    DarkToggler,
    CartDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data: function () {
    return {
      userData: {},
    };
  },
  computed: {
    // a computed getter
    pictureUrl: function () {
      // `this` points to the vm instance
      if (this.$store.state.app.pictureUrl != "")
        return this.$store.state.app.pictureUrl;
      if (this.userData.pictureurl) return this.userData.pictureurl;
      else return "empty.jpg";
    },
    companyPictureUrl: function () {
      if (this.$store.state.app.companyPictureUrl != "")
        return this.$store.state.app.companyPictureUrl;
      if (this.userData.companypictureurl)
        return this.userData.companypictureurl;
      else return "empty.jpg";
    },
    screenLg: function () {
      return this.$store.state.app.windowWidth > 992;
    },
    selectedPlantName: function () {
      const selectedId = this.$store.state.app.selectedPlantId;
      if (this.$store.state.plantsModule.plantData.length > 0) {
        return (
          this.$store.state.plantsModule.plantData.filter(
            (x) => x.id == selectedId
          )[0]?.plantname ??
          this.$store.state.plantsModule.plantData[0]?.plantname
        );
      } else return "";
    },
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) this.userData = userData;
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      // Remove userData from localStorage
      localStorage.removeItem("userData");
      // Reset ability
      this.$ability.update(initialAbility);
      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>
