import companySettings from "./company";
import rations from "./rations";
export default [
  // {
  //   title: "Admin",
  //   resource: "AdminAuth",
  //   action: "read",
  //   icon: "PieChartIcon",
  //   children: [
  //     {
  //       title: "Dashboard",
  //       route: "Admin",
  //       resource: "Auth",
  //       action: "read",
  //       exact: true,
  //     },
  //     {
  //       title: "Companies",
  //       route: "Companies",
  //       resource: "Auth",
  //       action: "read",
  //       exact: true,
  //     },
  //   ],
  // },
  {
    icon: "AirplayIcon",
    title: "homePage",
    route: "dashboard",
    resource: "Auth",
    action: "read",
    exact: true,
  },
  ...rations,

  // {
  //   src: require("@/assets/images/icons/orders.svg"),
  //   title: "orders",
  //   route: "orders",
  //   resource: "Auth",
  //   action: "read",
  //   exact: true,
  // },
  // {
  //   src: require("@/assets/images/icons/visits.svg"),
  //   title: "visits",
  //   route: "visits",
  //   resource: "Auth",
  //   action: "read",
  //   exact: true,
  // },
  {
    src: require("@/assets/images/icons/ingredients.svg"),
    title: "ingredients",
    route: { name: "ingredients" },
    resource: "Auth",
    action: "read",
    exact: true,
  },
  {
    src: require("@/assets/images/icons/prices.svg"),
    title: "ingredientPrices",
    route: { name: "ingredientsPrices" },
    resource: "Auth",
    action: "read",
    exact: true,
  },
  {
    src: require("@/assets/images/icons/products.svg"),
    title: "nutrients",
    route: { name: "nutrients" },
    resource: "Auth",
    action: "read",
    exact: true,
  },
  // {
  //   src: require("@/assets/images/icons/ingredients.svg"),
  //   title: "companyProducts",
  //   route: "companyProduct",
  //   resource: "Auth",
  //   action: "read",
  //   exact: true,
  // },
  // {
  //   src: require("@/assets/images/icons/prices.svg"),
  //   title: "productPrices",
  //   route: "prices",
  //   resource: "Auth",
  //   action: "read",
  //   exact: true,
  // },
  {
    src: require("@/assets/images/icons/plants.svg"),
    title: "plants",
    route: "plants",
    resource: "Auth",
    action: "read",
    exact: true,
  },
  ...companySettings,
  {
    icon: "AirplayIcon",
    title: "asIf",
    route: { name: "as-if" },
    resource: "Admin",
    action: "read",
    exact: true,
  },
];
