export default [
  {
    src: require("@/assets/images/icons/settings.svg"),
    icon: "SettingsIcon",

    title: "companySettings",
    route: "general-settings",
    resource: "Auth",
    action: "read",
  },
  {
    src: require("@/assets/images/icons/users.svg"),
    title: "users",
    route: "users",
    resource: "Auth",
    action: "read",
    exact: true,
  },
];
