export default [
  {
    title: "rations",
    resource: "Auth",
    action: "read",

    children: [
      {
        src: require("@/assets/images/icons/milk.svg"),
        title: "dairyRations",
        route: {
          name: "plantRations",
          params: { tur: "dairy" },
        },
        resource: "Dairy",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/beef.svg"),
        title: "beefRations",
        route: {
          name: "plantRations",
          params: { tur: "beef" },
        },
        resource: "Beef",
        action: "read",
        exact: true,
      },
      {
        title: "sheepRations",
        route: { name: "sheepRations" },
        resource: "Sheep",
        action: "read",
        exact: true,
      },
      {
        title: "goatRations",
        route: { name: "goatRations" },
        resource: "Goat",
        action: "read",
        exact: true,
      },
    ],
  },
];
